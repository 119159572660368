<template>
    <div class="cmcad-list">
        <div class="background"></div>
        <Header class="cmcad-top" :navList="navList" @search="handleSearchEvent" :typeList="[]"
            :navCurrentId="navCurrentId" :logo="logoUrl" type="3"></Header>
        <div class="container content filter-wrapper pt10 pb10 pl10 pr10">
            <filter-tab title="学科" :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex', $event)"></filter-tab>
        </div>
        <div class="container content content-wrapper flex pt30 pb20 pl20 pr20 mt20">
            <div class="content-left t-l">
                <div class="color999 fs14 t-l mb20">会务通帮您找到相关内容 {{ databaseTotal }}篇</div>
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="interview-list" v-if="databaseList.length">
                        <div v-for="(item, index) in databaseList" :key="index" class="database-list pb20 t-l"
                            :class="{ 'mt20': index != 0 }" @click="goArticleDetail(item.id, item.abstract_id)">
                            <p class="fs16 color333 strong mb10" v-html="item.title"></p>
                            <div class="flex x-between color999 fs14 mb10">
                                <div class="row-1">
                                    <span v-if="item.real_name" class="color333 mr10" @click.stop="handleSearchNewEvent(item.real_name,'all_name')">作者：<span
                                            v-html="item.real_name"></span></span>
                                    <span v-if="item.one_org_cnname">{{ item.one_org_cnname }}</span>
                                </div>
                            </div>
                            <p v-if="item.keywords" class="fs14 color333 mb10">关键词：<span v-html="item.keywords"></span>
                            </p>
                            <div class="flex x-between color999 fs14">
                                <div class="row-1">
                                    <span v-if="item.cn_title" class="fs14 color333"  @click.stop="handleSearchNewEvent(item.cn_title,'cn_title')">来源：<span v-html="item.cn_title"></span></span>
                                </div>
                                <div class="ml20"><i class="med med-yanjing fs14 mr5"></i>{{ item.hits }}</div>
                            </div>
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!databaseList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="databaseTotal > 0">
                    <a-pagination :total="databaseTotal" v-model:current="databaseParams.page"
                        :page-size="databaseParams.limit" show-quick-jumper @change="getPaperList"
                        :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="content-right">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import filterTab from '@/components/filter-tab';
import Header from '@/components/layout/layout-header';
import { jsonFormat } from '@/utils/jsonFormat';

export default {
    name: "databaseSearch",
    components: {
        Footer, layoutRight, commonTitle, filterTab, Header
    },
    data() {
        return {
            searchName: '',
            logoUrl: 'images/logo.png',
            navList: [],//顶部菜单
            navCurrentId: '',//当前页面菜单对应下标
            likeIndex: -1,
            likeNumList: [
                { id: 1, name: '500以下' },
                { id: 2, name: '500~1000' },
                { id: 3, name: '1000~5000' },
                { id: 4, name: '5000以上' },
            ],
            databaseTotal: 0,
            databaseParams: {
                page: 1,
                limit: 10,
                // search_key:'',
            },
            databaseList: [],
            layoutRightConfig: {
                // 本月会议
                meeting: {
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 2,
                        is_now_year_meeting: 1,
                    },   // 参数条件
                },
                // 推荐直播
                live: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '相关直播', // 标题
                    data: {
                        page: 1,
                        limit: 3
                    },   // 参数条件
                },
                // 推荐视频
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '最新视频', // 标题
                    data: {
                        page: 1,
                        PageSize: 4
                    },   // 参数条件
                },
            },
            loading: true,
            subjectList: [],
            subjectIndex: -1,
            searchList: [],
        }
    },
    created() {
        let list = ['and', this.$route.query.search_type, this.$route.query.search_name]
        this.searchName = this.$route.query.search_name
        this.searchList.push(list)
        let obj = {
            "search": this.searchList
        }
        this.databaseParams.search_auto = JSON.stringify(obj)
        // this.databaseParams.search_key = this.$route.query.search_name
        this.getSubjectList()
        this.getPaperList()
    },

    methods: {
        handleSearchEvent(search_name,search_type) {
            this.databaseParams.page = 1
            this.searchName = search_name
            if (search_name) {
                if (this.searchList.length > 1) {
                    this.searchList[1][1] = search_type || 'title'
                    this.searchList[1][2] = search_name
                } else {
                    if (this.searchList.length) {
                        if (this.searchList[0][1] == 'operate_id') {
                            let search_types = search_type || 'title'
                            this.searchList.push(['and', search_types, search_name])
                        } else {
                            this.searchList[0][1] = search_type || 'title'
                            this.searchList[0][2] = search_name
                        }
                    }else{
                        let search_types = search_type || 'title'
                        this.searchList.push(['and', search_types, search_name])
                    }

                }
                let obj = {
                    "search": this.searchList
                }
                this.databaseParams.search_auto = JSON.stringify(obj)
            } else {
                if (this.searchList.length > 1) {
                    this.searchList.splice(1, 1)
                    let obj = {
                        "search": this.searchList
                    }
                    this.databaseParams.search_auto = JSON.stringify(obj)
                } else {
                    this.searchList = []
                    delete this.databaseParams.search_auto
                }
            }
            this.getPaperList()
        },
        handleSearchNewEvent(search_name,search_type) {
            if (search_name.indexOf('<span style="color:red;">') != -1) {
                search_name = search_name.replace('<span style="color:red;">', '').replace('</span>', '')
            }
            this.$router.push({
                path: '/database/index',
                query: {
                    search_name,
                    search_type: search_type
                }
            })
            this.handleSearchEvent(search_name,search_type)

        },
        getPaperList() {
            this.loading = true
            let search_key = this.searchName
            this.request.post('AbstractSearch', this.databaseParams).then(res => {
                this.databaseTotal = parseInt(res.data.count)
                let databaseList = res.data.list
                databaseList.forEach(item => {
                    item.keywords = item.keywords.replace(/关键词：|关键词:/g, '').replace(search_key, '<span style="color:red;">' + search_key + '</span>')
                    item.title = item.title.replace(search_key, '<span style="color:red;">' + search_key + '</span>')
                    item.cn_title = item.cn_title.replace(search_key, '<span style="color:red;">' + search_key + '</span>')
                    item.real_name = item.real_name.replace(search_key, '<span style="color:red;">' + search_key + '</span>')
                })
                this.databaseList = databaseList
                this.loading = false
            })
        },
        getSubjectList() {
            this.$store.dispatch('getSubjectList').then(res => {
                let subjectObj = {
                    name: 'operate_name',
                }
                this.subjectList = jsonFormat(res.data, subjectObj)
            })
        },
        handleFilterEvent(key, e) {
            key && (this[key] = e);
            if (this.subjectIndex != -1) {
                this.operated_id = this.subjectList[this.subjectIndex].id
            } else {
                this.operated_id = ''
            }
            if ((this.searchList[0] && this.searchList[0][1] != 'operate_id' && this.operated_id) || (!this.searchList.length && this.operated_id)) {
                this.searchList.unshift(['and', 'operate_id', this.operated_id],)
            } else {
                if (this.searchList[0][1] == 'operate_id' && this.operated_id == '') {
                    this.searchList.splice(0, 1)
                }
            }
            if (this.searchList.length) {
                let obj = {
                    "search": this.searchList
                }
                this.databaseParams.search_auto = JSON.stringify(obj)
            } else {
                delete this.databaseParams.search_auto
            }
            this.getPaperList()
        },
        goArticleDetail(id, classId) {
            this.$router.push({
                path: '/database/database-detail',
                query: {
                    id,
                    classId,
                    meeting_id: id,
                    search_name: this.databaseParams.search_key
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.cmcad-list {
    .background {
        width: 100%;
        height: 408px;
        background: linear-gradient(360deg, rgba(239, 235, 255, 0) 0%, #EEEAFF 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .cmcad-top {
        /deep/.ant-input-search {
            background: #ffffff;
            border-radius: 100px;
        }

        /deep/.ant-input,
        /deep/.ant-btn {
            height: 38px;
        }

        /deep/.ant-input-suffix {
            right: -15px;
        }
    }

    .content {
        width: 1240px;
        border-radius: 10px;
        height: auto;
        background: #ffffff;

        .filter-box {
            border-bottom: 0;
        }

        .content-left {
            width: 770px;

            .database-list {
                border-bottom: 1px dashed #D8D8D8;
                cursor: pointer;
            }

            /deep/.ant-spin {
                width: 100%;
            }
        }

        .content-right {
            width: 398px;
        }
    }
}
@media screen and (max-width:768px){
    .cmcad-list{
        .cmcad-top {

            /deep/.ant-input-suffix {
                right: 0;
            }
            /deep/.search-header{
                height: 25px;
                right: 0;
                .ml10{
                    width: 60px!important;
                    height: 25px!important;
                }
            }
            /deep/.search{
                width: calc(100% - 100px)!important;
            }
            /deep/.advanced-search{
                color: #fff;
            }
        }
    }
    .filter-wrapper {
        padding: 2px 0;
        border-radius: 0!important;
        position: fixed;
        top: 50px;
        background: #f2f4fa;
        z-index: 999999;
    }
    .content-wrapper{
        width: 100%!important;
        display: block;
        margin-top: 50px;
        padding: 0 10px;
        border-radius: 0!important;
        .content-left{
            width: 100%!important;
        }
        .content-right{
            margin-top: 50px;
            width: 100%!important;
        }
    }
}
</style>